import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { AppConfigurationService, BrowserStorageService, LoadingService, SidebarService } from './core/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent implements OnInit, OnDestroy {
  isOnline: boolean;
  isLoading: boolean;
  private subs = [];

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private loadingService: LoadingService,
    private sidebarService: SidebarService,
    private appConfig: AppConfigurationService,
    private storageService: BrowserStorageService)
  {
    this.router.events.subscribe(this.checkRouterEvents.bind(this));
    const accessToken = this.storageService.getCookie('access_token');
    if (!accessToken) {
      window.location.href = this.appConfig.getCrystalBaseUrl();
    }
  }

  ngOnInit(): void {
    this.subs.push(
      this.loadingService.isLoading$.subscribe(isLoading => { 
        this.isLoading = isLoading;
        this.cd.detectChanges();
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  private checkRouterEvents(routerEvent: Event): void {
    if (routerEvent instanceof NavigationStart) {
      this.sidebarService.toggleRoleDrawer$.next(false);
      this.loadingService.setLoading(true);
    }

    if (routerEvent instanceof NavigationEnd || routerEvent instanceof NavigationError || routerEvent instanceof NavigationCancel) {
      this.loadingService.setLoading(false);
    }
  }
}
