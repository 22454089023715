import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { LoginService } from '../services';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, NavigationExtras, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private loginService: LoginService, private activatedRoute: ActivatedRoute) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this.loginService.isLoggedIn()) {
      return true;
    }

    // TODO token validation to throw the user from the session or restore it

    const extras: NavigationExtras = {
      skipLocationChange: true,
      queryParams: { recursiveUrl: state.url }
    };
    
    this.router.navigate(['/login'], extras);
    return false;
  }
}
