export interface UserResponse {
  status: number,
  message: string,
  user: UserVm
}

export interface IdentityUserOfString {
  id?: string | undefined;
  userName?: string | undefined;
  normalizedUserName?: string | undefined;
  email?: string | undefined;
  normalizedEmail?: string | undefined;
  emailConfirmed?: boolean;
  passwordHash?: string | undefined;
  securityStamp?: string | undefined;
  concurrencyStamp?: string | undefined;
  phoneNumber?: string | undefined;
  phoneNumberConfirmed?: boolean;
  twoFactorEnabled?: boolean;
  lockoutEnd?: string | undefined;
  lockoutEnabled?: boolean;
  accessFailedCount?: number;
}

export interface IdentityUser extends IdentityUserOfString {
}

export interface ApplicationUser extends IdentityUser {
  roles?: IdentityUserRoleOfString[] | undefined;
}

export interface UserVm extends ApplicationUser {
  claims?: Claim[] | undefined;
  name?: string | undefined;
  surname?: string | undefined;
  roleList?: string[] | undefined;
  tenants?: TenantUser[] | undefined;
  status?: string | undefined;
}

export interface UserRequest {
  id: string;
  email?: string;
  roles?: string[];
  claims?: Claim[];
  userName?: string;
  tenantClients?: TenantClientUser[];
  fullName?: string;
  isEnabled?: boolean;
  phoneNumber?: string;
  isLockedOut?: boolean;
}

export interface UserTenantRequest {
  userId: string;
  status?: string;
  roles?: string[];
  tenantId?: string;
}

export interface Claim {
  type: string,
  value: string,
}

export interface TenantClientUser {
  status?: string,
  roles?: string[],
  tenantId: string,
  tenantFk: string,
  clientId: string,
  tenantName: string,
}

export interface TenantUser extends Tenant {
  status: string;
  roles: ApplicationRole[];
}

export interface Tenant {
  id?: string | undefined;
  no?: string | undefined;
  name?: string | undefined;
  extSystem?: TenantExtSystem | undefined;
  clients?: TenantClient[] | undefined;
  roles: ApplicationRole[];
}

export interface TenantExtSystem {
  tenantFk?: string | undefined;
  systemCode?: string | undefined;
}

export interface TenantClient {
  clientId?: string | undefined;
  tenantFk?: string | undefined;
  clientName?: string | undefined;
}

export interface IdentityRoleOfString {
  id?: string | undefined;
  name?: string | undefined;
  normalizedName?: string | undefined;
  concurrencyStamp?: string | undefined;
}

export interface IdentityRole extends IdentityRoleOfString {
}

export interface ApplicationRole extends IdentityRole {
  users?: IdentityUserRoleOfString[] | undefined;
  claims?: IdentityRoleClaimOfString[] | undefined;
}

export interface IdentityUserRoleOfString {
  userId?: string | undefined;
  roleId?: string | undefined;
}

export interface IdentityRoleClaimOfString {
  id?: number;
  roleId?: string | undefined;
  claimType?: string | undefined;
  claimValue?: string | undefined;
}

export const createDefaultUserVm = () => ({
  id: '-1',
  email: '',
  roles: [],
  roleList: [],
  userName: '',
  tenants: [],
});

export const createDefaultUserResponse = () => ({
  status: 200,
  message: 'Success',
  user: {
    id: '-1',
    email: '',
    roles: [],
    roleList: [],
    userName: '',
    tenants: [],
  }
});

export interface UsersListResponse{
  status: string;
  message: string;
  users: UserVm[];
}

export interface FederatedClient {
  id: string;
  code: string;
  name: string;
  userId: string;
  userFk: string;
  clientId: string;
  clientSecret: string;
  unlinkUrl: string;
  linkingUrl: string;
  matchingUrl: string;
  authorizeUrl: string;
  validateUserUrl: string;
  validateTokenUrl: string;
}

export interface FederatedClientResponse {
  status: number;
  message: string;
  federatedClient: FederatedClient;
}

export interface FederatedClientListResponse {
  status: number;
  message: string;
  federatedClients: Array<FederatedClient>;
}
