import { Injectable } from '@angular/core';
import { Route, Routes } from '@angular/router';
import { Subject, BehaviorSubject } from 'rxjs';
import { RoleVm } from '../models/tenantvm.model';
import { SidebarModel, createSideBar, UserVm, Tenant } from '../models';

@Injectable({
  providedIn: 'root',
})

export class SidebarService {
  private disable = new Subject<boolean>();
  private content = new Subject<SidebarModel[]>();
  private mode = new BehaviorSubject<string>('push');
  private tenant = new BehaviorSubject<Tenant>(null);
  private isOpen = new BehaviorSubject<boolean>(false);
  
  roleDrawerRoleList: RoleVm[];
  mode$ = this.mode.asObservable();
  isOpen$ = this.isOpen.asObservable();
  tenant$ = this.tenant.asObservable();
  disable$ = this.disable.asObservable();
  
  userIsAdmin$ = new BehaviorSubject<boolean>(false);
  loadUserRoles$: BehaviorSubject<UserVm> = new BehaviorSubject<UserVm>(null);
  toggleRoleDrawer$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _rootPath: string = 'tenant';
  private _routes: Routes = [
    {
      path: 'new',
      data: {
        disabled: false,
        icon: 'add_to_photos',
        title: 'modules.main.addClient'
      }
    }
  ];

  content$() {
    return this.content.asObservable();
  }

  setContent(mainRoute: string, childRoutes: Route[]): void {
    const routePrefix = `/${mainRoute}`;
    const sideBarList: SidebarModel[] = childRoutes.map((route) => {
      const icon: any = route.data ? route.data.icon : null;
      const content: string = route.data ? route.data.title : '';
      const disabled: boolean = route.data ? route.data.disabled : false;
      const url = route.path !== null ? `${routePrefix}/${route.path}` : null;
      return createSideBar({ content, url, disabled, icon });
    });

    this.content.next(sideBarList);
  }

  disableSidebar(url: string): void {
    if (url === '/login') {
      this.disable.next(false);
    } else {
      this.disable.next(true);
    }
  }

  openSideMenu() {
    this.isOpen.next(true);
  }

  closeSideMenu() {
    this.isOpen.next(false);
  }

  toggleSideMenu() {
    this.isOpen.next(!this.isOpen.value);
  }

  changeMode(mode: string) {
    this.mode.next(mode);
  }

  setTenant(tenant: Tenant) {
    this.tenant.next(tenant);
  }

  get rootPath(): string {
    return this._rootPath;
  }

  get routes(): Routes {
    return this._routes;
  }
}
