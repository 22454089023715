const THREE_SECONDS = 3000;

export interface NotificationVm {
  type: string;
  title: string;
  body: string;
  id?: number;
  timeout?: number;
}

export const enum NotificationType {
  Info = 'Info',
  Success = 'Success',
  Warn = 'Warn',
  Error = 'Error',
}

export const createSuccessNotification = (title: string, body: string, timeout?: number): NotificationVm => ({
  timeout: timeout ? timeout : THREE_SECONDS,
  title,
  body,
  type: NotificationType.Success,
});

export const createErrorNotification = (title: string, body: string, timeout?: number): NotificationVm => ({
  timeout: timeout ? timeout : THREE_SECONDS,
  title,
  body,
  type: NotificationType.Error,
});

export const createWarningNotification = (title: string, body: string, timeout?: number): NotificationVm => ({
  timeout: timeout ? timeout : THREE_SECONDS,
  title,
  body,
  type: NotificationType.Warn,
});

export const createInfoNotification = (title: string, body: string, timeout?: number): NotificationVm => ({
  timeout: timeout ? timeout : THREE_SECONDS,
  title,
  body,
  type: NotificationType.Info,
});

export const createCustomNotification = (title: string, body: string, type: NotificationType, timeout?: number): NotificationVm => ({
  timeout: timeout ? timeout : THREE_SECONDS,
  title,
  body,
  type,
});

export interface PushNotificationModel {
  notification: NotificationMessageModel;
  to: string;
}

export interface NotificationMessageModel {
  title: string;
  body: string;
  icon: string;
}
