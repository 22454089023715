<mat-toolbar
  fxLayout="row"
  [class]="toolbar"
  *ngIf="!isFramelessMode"
  [style.--tenant-color]="tenantColor"
  [style.--profile-color]="profileColor"
  [style.--backoffice-color]="backofficeColor">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="h-100 w-100">
    <div fxLayout="row" class="h-100">
      <!-- Menu Toggle -->
      <button mat-raised-button name="menu" [class]="toolbar" [class.nav-button]="true" (click)="sidebarMenuToggle()" [disabled]="!(isLoggedIn$ | async)">
        <mat-icon>menu</mat-icon>
      </button>

      <!-- App Name and Description -->
      <div fxLayout="column" fxLayoutAlign="center start" fxFlexOffset="1.5rem" *ngIf="isLoggedIn$ | async">
        <div class="text--xlarge text--white text--bolder" style="line-height: 28px;"> {{ header }} </div>
        <div class="text--small text--white" style="line-height: 16px;"> {{ subtitle | translate }} </div>
      </div>
    </div>

    <div fxLayout="row" *ngIf="isLoggedIn$ | async">
      <!-- FAQs -->
      <div *ngIf="faqUrl" fxLayoutAlign="center center" class="h-100 nav-button more-horiz">
        <button mat-raised-button (click)="goToFaq()"><strong>FAQs</strong></button>
      </div>

      <!-- Languages -->
      <div fxLayoutAlign="center center" class="h-100 nav-button more-horiz">
        <button mat-icon-button [matMenuTriggerFor]="langMenu" fxLayout="row">
          <span class="{{ 'fp fp-md ' + currentLang.code }}" style="margin-right: 0.2rem;"></span>
          {{' ' + currentLang.label }}
        </button>
      </div>

      <!-- Languages Menu-->
      <mat-menu #langMenu="matMenu">
        <button *ngFor="let lang of languages" mat-menu-item (click)="setCurrentLang(lang)">
          <span class="{{ 'fp fp-md ' + lang.code }}"></span> {{lang.label}}
        </button>
      </mat-menu>

      <!-- User Account -->
      <div fxLayoutAlign="center center" class="h-100 nav-button more-horiz">
        <button mat-icon-button [matMenuTriggerFor]="accountMenu">
          <mat-icon color="white">account_circle</mat-icon>
        </button>
      </div>

      <!-- User Account Menu -->
      <mat-menu #accountMenu="matMenu">
        <!-- <button mat-menu-item routerLink="/configuration"> -->
          <button mat-menu-item (click)="goToUserProfile()">
          <mat-icon>person</mat-icon>
          <span>{{ 'modules.main.profile' | translate }}</span>
        </button>
        <button mat-menu-item (click)="goToUserPasswordChange()">
          <mat-icon>lock</mat-icon>
          <span>{{ 'modules.main.password' | translate }}</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="doLogoutCrystal()">
          <mat-icon>input</mat-icon>
          <span>{{ 'modules.main.logout' | translate }}</span>
        </button>
      </mat-menu>
    </div>
  </div>
</mat-toolbar>
