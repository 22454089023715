import {  Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LoginService, AppConfigurationService, SidebarService, ThemeService, LangSelection } from '../../services';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {
  documentElement: HTMLElement;
  header = '';
  appMode = '';
  toolbar = '';
  subtitle = '';
  faqUrl: string;
  appName: string;
  tenantColor = '';
  profileColor = '';
  showButton = false;
  public toogle = '';
  deferredPrompt: any;
  backofficeColor = '';
  subs: Subscription[] = [];
  appMode$: Observable<string>;
  
  isLoggedIn$: Observable<boolean>;
  isFramelessMode: boolean = false;

  // Crystal Login
  private readonly RETURNPARAMCRYSTAL = 'returnurl';
  private readonly LOGOUTCRYSTAL = '/Logout/Logout?';
  private readonly CLIENTIDPARAMCRYSTAL = 'clientId';

  // App Modes
  private readonly TENANT = {
    toolbar: 'tenant-toolbar',
    subtitle: 'modules.main.tenantMode',
  };

  private readonly PROFILE = {
    toolbar: 'profile-toolbar',
    subtitle: 'modules.main.userMode',
  };

  private readonly BACKOFFICE = {
    toolbar: 'backoffice-toolbar',
    subtitle: 'modules.main.backofficeMode',
  };

  public get languages() {
    return this.appConfig.languages;
  }

  public get currentLang() {
    return this.appConfig.getCurrentLanguage();
  }

  constructor(
    private router: Router,
    private themeService: ThemeService,
    private loginService: LoginService,
    private sideBarService: SidebarService,
    private appConfig: AppConfigurationService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    // Mode
    let m = this.loginService.appMode$().subscribe((appMode) => {
      this.appMode = appMode;
      if (appMode === 'profile') {
        this.toolbar = this.PROFILE.toolbar;
        this.subtitle = this.PROFILE.subtitle;
      } else if (appMode === 'tenant') {
        this.toolbar = this.TENANT.toolbar;
        this.subtitle = this.TENANT.subtitle;
      } else if (appMode === 'backoffice') {
        this.toolbar = this.BACKOFFICE.toolbar;
        this.subtitle = this.BACKOFFICE.subtitle;
      }
    });
    this.subs.push(
      m,
      this.themeService.isFramelessMode$.subscribe(isFrameless => this.isFramelessMode = isFrameless)
    );

    this.header = this.appConfig.getHeader();
    this.faqUrl = this.appConfig.getFaqUrl();
    this.appName = this.appConfig.getAppName();
    this.appMode$ = this.loginService.appMode$();
    this.isLoggedIn$ = this.loginService.isLoggedIn$();
    this.tenantColor = this.appConfig.getTenantColor();
    this.profileColor = this.appConfig.getProfileColor();
    
    this.backofficeColor = this.appConfig.getBackofficeColor();
    this.documentElement = document.documentElement;
  }

  sidebarMenuToggle(): void {
    this.sideBarService.toggleSideMenu();
  }

  doLogoutCrystal() {
    window.location.replace(
      this.appConfig.getCrystalBaseUrl() +
      `${this.LOGOUTCRYSTAL}${
        this.RETURNPARAMCRYSTAL
      }=${this.appConfig.getCrystalReturnUrl()}&${
        this.CLIENTIDPARAMCRYSTAL
      }=${this.appConfig.getCrystalClientId()}`
    );
  }

  setCurrentLang(lang: LangSelection) {
    this.appConfig.setLanguage(lang);
    this.cd.detectChanges();
  }

  goToFaq(): void {
    window.open(this.faqUrl, "_blank");
  }

  goToUserProfile(): void {
    this.router.navigate(['/profile']);
  }

  goToUserPasswordChange(): void {
    if(window.location.pathname.toLowerCase().trim() === "/profile"){
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate(['/profile'], { queryParams: { tab: 1 } });
      });
    }else{
      this.router.navigate(['/profile'], { queryParams: { tab: 1 } });
    }
  }

  ngOnDestroy(): void {
    this.subs.map(s => s.unsubscribe());
  }
}
