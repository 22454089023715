<aside
  fxLayout="column"
  class="w-100 h-100"
  fxLayoutAlign="space-between start"
  [style.--tenant-color]="tenantColor"
  [style.--profile-color]="profileColor"
  [style.--backoffice-color]="backofficeColor">
  <section class="w-100">
    <!-- Subtitle -->
    <p class="sub-section" style="height: 15px;">
      {{ isOpen ? (subtitle | translate) : ' ' }}
    </p>

    <!-- Sections -->
    <mat-nav-list>
      <a mat-list-item
        *ngIf="appMode !== 'backoffice'"
        [matMenuTriggerFor]="tenantMenu"
        style="background-color: #e1efe0;"
        [disabled]="tenants === null || tenants === undefined || tenants.length <= 0"
        [class.disabled-section]="tenants === null || tenants === undefined || tenants.length <= 0">
          <mat-icon> sync_alt </mat-icon>
          <strong>{{ isOpen ? tenant?.name : ''}}</strong>
      </a>

      <!-- Tenants Menu-->
      <mat-menu #tenantMenu="matMenu" class="tenant-menu">
        <button *ngFor="let tenant of tenants" mat-menu-item (click)="changeTenant(tenant)">
          {{tenant.name}}
        </button>
      </mat-menu>

      <a mat-list-item 
        (click)="sidebarMenuToggle()"
        *ngFor="let route of content"
        [class]="isActive(route)"
        [routerLink]="route.disabled ? null : route.url">
          <mat-icon>{{ route.icon }}</mat-icon>
          <strong>{{ isOpen ? (route.content | translate) : ''}}</strong>
      </a>
    </mat-nav-list>

    <!-- Tenant Management -->
    <div *ngIf="tenant && appMode !== 'backoffice'" style="margin-top: 20px;">
      <mat-divider></mat-divider>
      <p class="sub-section" style="height: 15px;">
        {{ isOpen ? ('modules.main.configuration' | translate) : ' ' }}
      </p>
      <mat-nav-list>
        <a mat-list-item 
          (click)="sidebarMenuToggle()"
          *ngFor="let route of tenantRoutes"
          [class]="isActive(route)"
          [class.disabled-section]="route.disabled"
          [routerLink]="route.disabled ? null : route.url">
            <mat-icon class="material-icons-round">{{ route.icon }}</mat-icon>
            <strong>{{ isOpen ? (route.content | translate) : ''}}</strong>
        </a>
      </mat-nav-list>
    </div>
  </section>

  <!-- User Management -->
  <section class="w-100">
    <mat-divider></mat-divider>
    <p *ngIf="isOpen" class="sub-section"> {{ 'modules.main.user' | translate }} </p>
    <mat-nav-list>
      <a mat-list-item 
        (click)="sidebarMenuToggle()"
        *ngFor="let route of profileRoutes"
        [class]="isActive(route)"
        [class.disabled-section]="route.disabled"
        [routerLink]="route.disabled ? null : route.url">
          <mat-icon class="material-icons-round">{{ route.icon }}</mat-icon>
          <strong>{{ isOpen ? (route.content | translate) : ''}}</strong>
      </a>
    </mat-nav-list>
    <mat-divider></mat-divider>
    <p class="version">V1.456</p>
  </section>
</aside>