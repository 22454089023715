import { Injectable } from '@angular/core';
import { ThemeService } from './theme.service';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { BrowserStorageService } from './browser-storage.service';
import { AppConfigurationModel, EmailConfiguration, EmailContent, FormKey } from '../models';

export interface LangSelection {
  code: string;
  label: string;
  locale: string;
  enabled: boolean;
}
@Injectable({
  providedIn: 'root',
})
export class AppConfigurationService {
  public languages: LangSelection[] =
  [
    { code: 'de', label: 'DE', locale: 'de', enabled: true },
    { code: 'it', label: 'IT', locale: 'it', enabled: true },
    { code: 'fr', label: 'FR', locale: 'fr', enabled: true },
    { code: 'gb', label: 'EN', locale: 'en', enabled: true },
    { code: 'es', label: 'ES', locale: 'es', enabled: true }
  ];
  
  private _appUrl = '';
  private _appUrlSsl = '';
  private _crystalUrl = '';
  public isConfigLoaded = false;
  private currentLang = this.languages[0];
  private configuration: AppConfigurationModel;
  private readonly STORED_LANGUAGE_KEY: string = 'i18nextLng';

  constructor(
    private http: HttpClient,
    private themeService: ThemeService,
    private translate: TranslateService,
    private storage: BrowserStorageService
  ) {
    this.themeService.loadTheme();
  }

  async loadConfigOnline(): Promise<void> {
    this.configuration = await this.http.get<AppConfigurationModel>('/api/appconfig').toPromise();
    this.setAppUrls();
    await this.loadLangs();
    this.isConfigLoaded = true;
  }

  private async loadLangs(): Promise<void> {
    var langLocaleArray: string[] = this.languages.map((lang) => {
      if(lang.enabled){
        return lang.locale
      }
    })
    this.translate.addLangs(langLocaleArray);
    var storedLang = this.storage.getItemFromLocalStorage(this.STORED_LANGUAGE_KEY)
    if(storedLang?.trim().length){
      var matchingLanguage: LangSelection = this.languages.find(lang => lang.locale.toLowerCase() === storedLang.toLowerCase());
      if(matchingLanguage){
        this.currentLang = matchingLanguage;
      }
    }
    this.translate.setDefaultLang(this.languages[0].locale);
    try{
      await this.translate.use(this.currentLang.locale);
    }catch (err){
      console.log("Languages preload error", err)
    }
  }

  setLanguage(lang: LangSelection){
    this.currentLang = lang;
    this.storage.setItemToLocalStorage(this.STORED_LANGUAGE_KEY, lang.locale)
    this.translate.use(lang.locale);
  }

  getCurrentLanguage(): LangSelection {
    return this.currentLang;
  }

  getFormKeys(): FormKey[] {
    return JSON.parse(this.configuration.formKeys);
  }

  getAppUrl(): string {
    return this._appUrl;
  }

  getHeader(): string {
    return this.configuration.appConfiguration.header;
  }

  getFavicon(): string {
    return this.configuration.appConfiguration.favicon;
  }

  getAppTitle(): string {
    return this.configuration.appConfiguration.title;
  }
  
  getAppName(): string {
    return this.configuration.appConfiguration.appName;
  }

  getAsgardUrl(): string {
    return this.configuration.asgardConfiguration.asgardConnectionString;
  }

  getUnlinkEndpoint(): string {
    return this.configuration.appConfiguration.unlinkEndpoint;
  }

  getLinkingEndpoint(): string {
    return this.configuration.appConfiguration.linkingEndpoint;
  }

  getAppUrlSsl(): string {
    return this._appUrlSsl;
  }

  getCrystalReturnUrl(): string {
    return this._appUrlSsl;
  }
  
  getFaqUrl(): string {
    return this.configuration.appConfiguration.faqUrl;
  }

  getAdminRoles(): string[] {
    return this.configuration.appConfiguration.adminRoles;
  }

  getLoginDomains(): string[] {
    return this.configuration.appConfiguration.loginDomains;
  }

  getAccountDomains(): string[] {
    return this.configuration.appConfiguration.accountDomains;
  }

  getTenantColor(): string {
    return this.configuration.appConfiguration.tenantColor;
  }

  getProfileColor(): string {
    return this.configuration.appConfiguration.profileColor;
  }

  getPasswordRegex(): string {
    return this.configuration.asgardConfiguration.passwordRegex;
  }

  getSecondaryColor(): string {
    return this.configuration.appConfiguration.secondaryColor;
  }

  getBackofficeColor(): string {
    return this.configuration.appConfiguration.backofficeColor;
  }

  getAddTenantButton(): boolean {
    return this.configuration.appConfiguration.addTenantButton;
  }

  getExternalLoginUrl(clientId: string): string {
    return this.configuration.appConfiguration.externalLoginUrl[clientId];
  }

  getAllowExternalConnections(): boolean {
    return this.configuration.appConfiguration.allowExternalConnections;
  }

  getMaxUsersPerTenant(): number {
    return this.configuration.appConfiguration.maxUsersPerTenant;
  }

  getCrystalBaseUrl(): string {
    return this._crystalUrl;
  }

  getCrystalClientId(): string {
    return this.configuration.crystalConfiguration.crystalClientId;
  }

  getEmailConfiguration(): EmailConfiguration {
    return this.configuration.emailConfiguration;
  }

  getProductOwner(): string {
    return this.configuration.appConfiguration.productOwner;
  }

  getEmailContent(key: string): EmailContent {
    // Email Content
    let emailContent: EmailContent = this.translate.instant(key);
    let emailConfig: EmailConfiguration = this.getEmailConfiguration();
    
    // Email Summary
    this.translate.get(
      `${key}.summary`, {
        supportName: emailConfig.supportName
      }).subscribe((summary: string) => {
      emailContent.summary = summary;
    });

    // Email Footer
    this.translate.get(
      `${key}.footer`, {
        supportUrl: emailConfig.supportUrl,
        supportLink: emailConfig.supportLink,
        supportName: emailConfig.supportName
      }).subscribe((footer: string) => {
      emailContent.footer = footer;
    });

    return emailContent;
  }

  setAppUrls() {
    const hostname = window.location.hostname;
    if (hostname !== 'localhost') {
      this.reconstructUrl(hostname);
    } else {
      this._appUrl = this.configuration.appConfiguration.appUrl;
      this._appUrlSsl = this.configuration.appConfiguration.appUrlSsl;
      this._crystalUrl = this.configuration.crystalConfiguration.crystalBaseUrl;
    }
  }

  reconstructUrl(hostname: string) {
    // const domainSuffixIndex = hostname.lastIndexOf('.');
    // const domainSuffix = hostname.substring(domainSuffixIndex + 1);
    // const domainWithoutSuffix = hostname.substring(0, domainSuffixIndex);
    // const appDomainIndex = domainWithoutSuffix.lastIndexOf('.');
    // const appDomain = domainWithoutSuffix.substring(appDomainIndex + 1);
    // if (appDomain !== undefined && appDomain !== null && appDomain !== '' &&
    //   domainSuffix !== undefined && domainSuffix !== null && domainSuffix !== '') {
    //     // let subdomain = hostname.replace(`.${appDomain}.${domainSuffix}`, '');
    //     this._appUrl = `${this.configuration.appConfiguration.appUrl}.${appDomain}.${domainSuffix}`;
    //     this._appUrlSsl = `${this.configuration.appConfiguration.appUrlSsl}.${appDomain}.${domainSuffix}`;
    //     this._crystalUrl = `${this.configuration.crystalConfiguration.crystalBaseUrl}.${appDomain}.${domainSuffix}`;
    // }

    let domain = this.isValidDomain(hostname) ? hostname : this.getAccountDomains()[0];
    this._appUrl = `${this.configuration.appConfiguration.appUrl}${domain}`;
    this._appUrlSsl = `${this.configuration.appConfiguration.appUrlSsl}${domain}`;
    this._crystalUrl = `${this.configuration.crystalConfiguration.crystalBaseUrl}${this.getCrystalDomain(domain)}`;
  }

  isValidDomain(domain: string): boolean {
    return this.getAccountDomains().includes(domain);
  }

  getCrystalDomain(domain: string): string {
    let index = this.getAccountDomains().indexOf(domain);
    return this.getLoginDomains()[index];
  }
}
