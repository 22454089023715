import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ThemeModel, createTheme } from '../models';
import { BrowserStorageService } from './browser-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private themes: ThemeModel[] = [];

  private currentTheme = '';
  private defaultTheme = 'indigo-pink';

  public isFramelessMode$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private browserStorage: BrowserStorageService) {
    // nada
    const indigo = createTheme({ name: 'Indigo Pink', id: 'indigo-pink' });
    const deeppurple = createTheme({ name: 'Deep purple amber', id: 'deeppurple-amber' });
    const purplegreen = createTheme({ name: 'Purple Green', id: 'purple-green' });
    const pinkblue = createTheme({ name: 'Pink Bluegrey', id: 'pink-bluegrey' });
    const dark = createTheme({ name: 'Dark', id: 'dark-theme' });
    this.themes = [indigo, deeppurple, purplegreen, pinkblue, dark];
  }

  setFramelessMode(value: boolean){
    this.isFramelessMode$.next(value);
  }

  getThemes(): Observable<ThemeModel[]> {
    return of(this.themes);
  }

  loadTheme(): void {
    const theme: string | null = this.browserStorage.getItemFromLocalStorage('theme');

    if (theme && document.body.classList.length === 0) {
      // load stored theme
      document.body.classList.add(theme);
      this.currentTheme = theme;
    } else if (!theme && document.body.classList.length === 0) {
      // load default
      document.body.classList.add(this.defaultTheme);
      this.currentTheme = this.defaultTheme;
    } else {
      // change theme
      this.changeTheme(theme);
    }
  }

  get default(): string {
    return this.defaultTheme;
  }

  get current(): string {
    return this.currentTheme;
  }

  public changeTheme(theme: string): void {
    this.browserStorage.setItemToLocalStorage('theme', theme);
    document.body.classList.replace(this.currentTheme, theme);
    this.currentTheme = theme;
  }
}
