import { Injectable, OnDestroy } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl
  implements OnDestroy {
  subs: Subscription[] = [];
  OF_LABEL = 'of';

  constructor(private translate: TranslateService) {
    super();

    this.subs.push(
        this.translate.onLangChange
            .subscribe(() => {
            this.getAndInitTranslations();
        })
    );

    this.getAndInitTranslations();
  }

  ngOnDestroy() {
      this.subs.forEach(s => s.unsubscribe());
  }

  getAndInitTranslations() {
      this.subs.push(
        this.translate
        .get([
          'modules.tenant.custom-paginator.items-per-page',
          'modules.tenant.custom-paginator.next-page',
          'modules.tenant.custom-paginator.previous-page',
          'modules.tenant.custom-paginator.of-label',
        ])
        .subscribe(translation => {
          this.itemsPerPageLabel =
            translation['modules.tenant.custom-paginator.items-per-page'];
          this.nextPageLabel = translation['modules.tenant.custom-paginator.next-page'];
          this.previousPageLabel =
            translation['modules.tenant.custom-paginator.previous-page'];
          this.OF_LABEL = translation['modules.tenant.custom-paginator.of-label'];
          this.changes.next();
        })
      );
  }

  getRangeLabel = (
    page: number,
    pageSize: number,
    length: number,
  ) => {
    if (length === 0 || pageSize === 0) {
      return `0 ${this.OF_LABEL} ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} ${
      this.OF_LABEL
    } ${length}`;
  };
}