import { Route, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { SidebarModel, Tenant } from '../../models';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AppConfigurationService, LoginService, SidebarService } from '../../services';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss'],
})

export class SidebarMenuComponent implements OnInit {
  
  private tenantRoutesAdded: boolean = false;

  appMode = '';
  subtitle = '';
  isOpen = false;
  isAdmin: boolean;
  tenantColor = '';
  profileColor = '';
  routes: Route[] = [];
  backofficeColor = '';
  tenant: Tenant = null;
  tenants: Tenant[] = [];
  content?: SidebarModel[];
  addTenantButton: boolean;
  subs: Subscription[] = [];
  appMode$: Observable<string>;
  externalConnections: boolean;
  tenantRoutes?: SidebarModel[];
  profileRoutes?: SidebarModel[] = [];

  // App Modes
  private readonly TENANT = {
    active: 'active-section-tenant',
    subtitle: 'modules.main.tenant',
  };

  private readonly PROFILE = {
    active: 'active-section-profile',
    subtitle: 'modules.main.tenant',
  };

  private readonly BACKOFFICE = {
    active: 'active-section-backoffice',
    subtitle: 'modules.main.backoffice',
  };

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private loginService: LoginService,
    private sidebarService: SidebarService,
    private appConfig: AppConfigurationService)
  { }

  ngOnInit(): void {
    // Mode
    this.setProfileRoutes();
    this.tenantColor = this.appConfig.getTenantColor();
    this.profileColor = this.appConfig.getProfileColor();
    this.backofficeColor = this.appConfig.getBackofficeColor();
    this.addTenantButton = this.appConfig.getAddTenantButton();
    this.routes = this.addTenantButton ? this.sidebarService.routes : [];
    let m = this.loginService.appMode$().subscribe((appMode) => {
      this.appMode = appMode;
      this.cd.detectChanges();

      if (appMode === 'profile' || appMode === 'tenant') {
        this.sidebarService.setContent(this.sidebarService.rootPath, this.routes);
        this.subtitle = this.PROFILE.subtitle;
        this.cd.detectChanges();
      } else {
        this.subtitle = this.BACKOFFICE.subtitle;
        this.cd.detectChanges();
      }
    });

    this.subs.push(
      m,
      this.sidebarService.userIsAdmin$.subscribe(value => { 
        this.isAdmin = value;
        if(this.tenantRoutes && this.tenantRoutesAdded){
          let index = this.tenantRoutes.findIndex(tenantRoute => tenantRoute.url.includes('/manage'))
          this.tenantRoutes[index].disabled = this.isAdmin ? false : true;
          this.cd.detectChanges();
        }
      })
    );
    
    // Content
    this.subs.push(
      this.sidebarService.content$().subscribe((content) => {
        this.content = content;
        this.cd.detectChanges();
      })
    );

    // Open
    this.subs.push(
      this.sidebarService.isOpen$.subscribe((isOpen) => {
        this.isOpen = isOpen;
      })
    );

    // Tenant list
    let tl = this.loginService.tenants$().subscribe((tenants) => {
      this.tenants = tenants;
      if (tenants !== null && tenants !== undefined && tenants.length > 0) {
        this.tenant = tenants[0];
        this.sidebarService.setTenant(this.tenant);
      }
    });
    this.subs.push(tl);

    // Tenant selected
    let ts = this.sidebarService.tenant$.subscribe((tenant) => {
      this.tenant = tenant;
      //this.isAdmin = false;
      if (this.tenant !== null && this.tenant !== undefined) {
        this.addTenantRoutes();
      }
    });
    this.subs.push(ts);
  }

  isActive(route: SidebarModel): any {
    if ((route.url === null && this.appMode === 'backoffice') ||
    (!route.disabled && this.normalizeText(route.url) === this.normalizeText(window.location.pathname)))
    {
      if (this.appMode === 'tenant') {
        return this.TENANT.active;
      } else if (this.appMode === 'profile') {
        return this.PROFILE.active;
      } else if (this.appMode === 'backoffice') {
        return this.BACKOFFICE.active;
      }
    }

    return '';
  }

  private normalizeText(text: string): string {
    if (text === null || text === undefined) {
      return text;
    } else {
      return text.replace(/\W/g, '').trim();
    }
  }

  sidebarMenuToggle(): void {
    this.sidebarService.toggleSideMenu();
  }

  changeTenant(tenant: Tenant) {
    this.tenant = tenant;
    this.sidebarService.setTenant(this.tenant);
    this.router.navigate([`tenant/${tenant.id}`]);
  }

  addTenantRoutes() {
    this.tenantRoutes = [];

    // Start
    this.tenantRoutes.push({
      icon: 'home',
      disabled: false,
      content: 'modules.main.start',
      url: `tenant/${this.tenant.id}`
    });

    // Tenant Info
    this.tenantRoutes.push({
      icon: 'person_pin',
      disabled: false,
      content: 'modules.main.tenantProfile',
      url: `tenant/${this.tenant.id}/info`
    });

    // User Management
    this.tenantRoutes.push({
      icon: 'group',
      disabled: this.isAdmin ? false : true,
      content: 'modules.main.usersManagement',
      url: `tenant/${this.tenant.id}/manage`
    });

    // License Management
    // this.tenantRoutes.push({
    //   icon: 'apps',
    //   disabled: true,
    //   content: 'modules.main.licenseManagement',
    //   url: `tenant/${this.tenant.id}/license`
    // });

    // Rights Management
    // this.tenantRoutes.push({
    //   icon: 'gavel',
    //   disabled: true,
    //   content: 'modules.main.rightsManagement',
    //   url: `tenant/${this.tenant.id}/rights`
    // });
    this.tenantRoutesAdded = true;
  }

  setProfileRoutes() {
    // User Profile
    this.profileRoutes.push({
      url: 'profile',
      icon: 'person',
      disabled: false,
      content: 'modules.main.userProfile'
    });

    // External Connections
    this.externalConnections = this.appConfig.getAllowExternalConnections();
    if (this.externalConnections) {
      this.profileRoutes.push({
        url: 'link',
        icon: 'link',
        disabled: false,
        content: 'modules.main.externalConnections'
      });
    }
  }

  ngOnDestroy(): void {
    this.subs.map(s => s.unsubscribe());
  }
}
