import { Subscription, Observable } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { HttpParams } from '@angular/common/http';
import { TenantVm } from '../../models/tenantvm.model';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoginService } from 'src/app/core/services/login.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppConfigurationService, BrowserStorageService, NotificationService } from '../../services';
import { createErrorNotification, UserVm, UserResponse, createWarningNotification } from '../../models';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  
  private loginSubscription: Subscription;
  private isLoggedInSubscription: Subscription;
  private currentUserSubscription: Subscription;
  private singleSignOnSubscription: Subscription;
  
  private readonly LOGINCRYSTAL = '/Login?';
  private readonly RETURNPARAMCRYSTAL = 'returnurl';
  private readonly CLIENTIDPARAMCRYSTAL = 'clientId';

  isLoading = false;
  errorMessage = '';
  loginForm: FormGroup;
  disableLoginsBtn = false;
  recursiveUrl = '/profile';
  tenantSelected: TenantVm = null;
  paramsArray: HttpParams = new HttpParams();
  favIcon: HTMLLinkElement = document.querySelector('#app-icon');

  constructor(
    private router: Router,
    private titleService: Title,
    private loginService: LoginService,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private appConfig: AppConfigurationService,
    private storageService: BrowserStorageService,
    private notificationService: NotificationService)
  {
    this.loginForm = new FormGroup({
      username: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
    });
  }

  ngOnInit(): void {
    // Set icon
    this.favIcon.href = this.appConfig.getFavicon();

    // Set title
    this.titleService.setTitle(this.appConfig.getAppTitle());

    this.checkQueryParams();
    this.doLoginCrystal();
  }

  doLoginCrystal() {
    this.disableLoginsBtn = true;
    if (this.storageService.getCookie('access_token') !== '' &&
        this.storageService.getCookie('access_token') !== null &&
        this.storageService.getCookie('access_token') !== undefined)
    {
      this.singleSignOn().subscribe(userResponse => {
        const userMapped = userResponse.user;
        this.loginService.setIsLoggedIn(true);
        if (userMapped.email !== null && userMapped.email !== undefined && userMapped.email !== '') {
          this.loginService.getUserByEmail(userMapped.email).toPromise().then((userResponse: UserResponse) => {
            this.loginService.setCurrentUser(userResponse.user);
            this.loginService.setTenants(userResponse.user.tenants);
            if (this.recursiveUrl.includes('/backoffice')) {
              let access = this.checkAccess(userResponse.user);
              this.recursiveUrl = access ? this.recursiveUrl : '/profile';

              this.router.navigateByUrl(this.recursiveUrl);
            } else {
              this.router.navigateByUrl(this.recursiveUrl);
            }
          }).catch((error) => {
            let notification = error.error.status === 500 ?
              createErrorNotification('User', this.translate.instant('modules.notifications.getUserByEmail.500')) :
              createWarningNotification('User', this.translate.instant('modules.notifications.getUserByEmail.400'));
            this.notificationService.addMessage(notification);
          });
        }
      }, _error => {
        this.doLogoutCrystal();
      });
    } else {
      this.doLogoutCrystal();
    }
  }

  singleSignOn(): Observable<UserResponse> {
    return this.loginService.singleSignOn$();
  }

  doLogoutCrystal(): void {
    window.location.replace(
      this.appConfig.getCrystalBaseUrl() +
      `${this.LOGINCRYSTAL}${
        this.RETURNPARAMCRYSTAL
      }=${this.appConfig.getCrystalReturnUrl()}&${
        this.CLIENTIDPARAMCRYSTAL
      }=${this.appConfig.getCrystalClientId()}`
    );
  }

  ngOnDestroy(): void {
    if (this.loginSubscription) { this.loginSubscription.unsubscribe(); }
    if (this.isLoggedInSubscription) { this.isLoggedInSubscription.unsubscribe(); }
    if (this.currentUserSubscription) { this.currentUserSubscription.unsubscribe(); }
    if (this.singleSignOnSubscription) { this.singleSignOnSubscription.unsubscribe(); }
  }

  private checkAccess(user: UserVm): boolean {
    let tenant = user.tenants.find(x => x.name === 'Odin');
    if (tenant !== undefined && tenant !== null) {
      let client = tenant.clients.find(x => x.clientId === 'valhalla');
      if (client !== undefined && client !== null) {
        return true;
      }
    }
    return false;
  }

  checkQueryParams() {
    const recursiveUrlParam: string = this.activatedRoute.snapshot.queryParams.recursiveUrl;
    this.recursiveUrl = recursiveUrlParam !== undefined && recursiveUrlParam !== null && recursiveUrlParam !== '' ?
    recursiveUrlParam : this.recursiveUrl;
  }
}
