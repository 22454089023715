<div fxLayout="column" class="h-100" fxLayoutAlign="start stretch">
  <app-nav-bar id="nav-bar"></app-nav-bar>
  <mat-sidenav-container fxFlex="100" [ngClass]="{'sidebar-opened' : isOpen$ | async, 'sidebar-closed': !(isOpen$ | async)}">
    <mat-sidenav [mode]="'side'" [opened]="true" hasBackdrop="false" *ngIf="(isLoggedIn$ | async) && !isFramelessMode">
      <app-sidebar-menu></app-sidebar-menu>
    </mat-sidenav>
    <mat-sidenav-content [ngClass]="isFramelessMode ? 'framelessSidenav' : ''">
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <!-- <app-footer></app-footer> -->
</div>
