import { BehaviorSubject } from 'rxjs';
import { Routes } from '@angular/router';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LoadingService, AppConfigurationService, NotificationService } from 'src/app/core/services';
import { BasicResponse, InvitationRequest, NewTenantRequest, RoleListResponse, RoleVm, TenantResponse } from 'src/app/core/models/tenantvm.model';
import { createErrorNotification, createSuccessNotification, createWarningNotification, UsersListResponse, UserTenantRequest, UserVm } from 'src/app/core/models';

@Injectable()
export class TenantService {
  private _routes: Routes = [];
  private _rootPath: string = '';
  public currentTenantUserList: UserVm[] = [];
  public tenantRouteFirstChildSubPushed: boolean = false;
  public readonly NUMINOS_ROLE_PREFIX: string = "numinos_";
  public refreshUsersList$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private loadingService: LoadingService,
    private appConfig: AppConfigurationService,
    private notificationService: NotificationService
  ) {}

  get routes(): Routes {
    return this._routes;
  }

  get rootPath(): string {
    return this._rootPath;
  }

  getById(tenantId: string) {
    const params = new HttpParams().append('tenantId', tenantId);
    return this.http.get<TenantResponse>('/api/tenant/GetById', {params});
  }
  
  getUsers(tenantId: string) {
    const params = new HttpParams().append('tenantId', tenantId);
    return this.http.get<UsersListResponse>('/api/tenant/GetUsers', {params});
  }

  setUserStatus(userId: string, status: string, tenantId: string) {
    const request: UserTenantRequest = {
      userId: userId,
      status: status,
      tenantId: tenantId
    };

    // Update status
    this.loadingService.setLoading(true);
    this.updateUser(request).toPromise().then((response: BasicResponse) => {
      this.notificationService.addMessage(createSuccessNotification('Tenant', this.translate.instant('modules.notifications.updateUser.200')));
      this.refreshUsersList$.next(true);
    }).catch((error) => {
      let notification = error.error.status === 500 ?
        createErrorNotification('Tenant', this.translate.instant('modules.notifications.updateUser.500')) :
        createWarningNotification('Tenant', this.translate.instant('modules.notifications.updateUser.400'));
      this.notificationService.addMessage(notification);
    }).finally(() => {
      this.loadingService.setLoading(false);
    });
  }

  activeInvitation(userId: string, status: string, tenantId: string) {
    const request: UserTenantRequest = {
      userId: userId,
      status: status,
      tenantId: tenantId
    };

    // Accept Invitation
    this.loadingService.setLoading(true);
    this.acceptInvitation(request).toPromise().then((response: BasicResponse) => {
      this.notificationService.addMessage(createSuccessNotification('Tenant', this.translate.instant('modules.notifications.updateUser.200')));
      this.refreshUsersList$.next(true);
    }).catch((error) => {
      let notification = error.error.status === 500 ?
        createErrorNotification('Tenant', this.translate.instant('modules.notifications.updateUser.500')) :
        createWarningNotification('Tenant', this.translate.instant('modules.notifications.updateUser.400'));
      this.notificationService.addMessage(notification);
    }).finally(() => {
      this.loadingService.setLoading(false);
    });
  }

  getRoles(tenantId: string) {
    const params = new HttpParams().append('tenantId', tenantId);
    return this.http.get<RoleListResponse>('/api/tenant/GetRoles', {params});
  }

  checkForNuminosRolesFromArray(inputArray: RoleVm[], makeFirstCharUpperCase?: boolean): RoleVm[] {
    let outputArray: RoleVm[] = [];
    inputArray.forEach(role => {
      let trimmedRole = role;
      trimmedRole.name = this.checkForNuminosRole(trimmedRole, makeFirstCharUpperCase)
      outputArray.push(trimmedRole);
    })
    return outputArray;
  }

  checkForNuminosRole(inputRole: string | RoleVm, makeFirstCharUpperCase?: boolean): string {
    if(!inputRole){
      return null;
    }

    if(typeof(inputRole) === 'string'){
      let roleCopy: string = inputRole;
      roleCopy = this.trimNuminosPrefix(roleCopy, makeFirstCharUpperCase);
      return roleCopy;
    }else{
      let roleCopy: RoleVm = inputRole;
      roleCopy.name = this.trimNuminosPrefix(roleCopy.name, makeFirstCharUpperCase);
      return roleCopy.name;
    }
  }

  private trimNuminosPrefix(input: string, makeFirstCharUpperCase?: boolean): string {
    let inputCopy = input;
    if(inputCopy?.toLowerCase().includes(this.NUMINOS_ROLE_PREFIX)){
      inputCopy = inputCopy.replace(this.NUMINOS_ROLE_PREFIX, '');
      if(makeFirstCharUpperCase){
        inputCopy = inputCopy[0].toUpperCase() + inputCopy.slice(1);
      }
      return inputCopy
    }
    return input;
  }

  setDefaultData(tenantId: string) {
    const params = new HttpParams().append('tenantId', tenantId);
    return this.http.get<BasicResponse>('/api/tenant/SetDefaultData', {params});
  }
  
  newTenant(request: NewTenantRequest) {
    return this.http.post('/api/tenant/NewTenant', request);
  }
  
  updateUser(request: UserTenantRequest) {
    return this.http.post<BasicResponse>('/api/tenant/UpdateUser', request);
  }

  acceptInvitation(request: UserTenantRequest) {
    return this.http.post<BasicResponse>('/api/tenant/AcceptInvitation', request);
  }
  
  deleteUser(request: UserTenantRequest) {
    return this.http.post<BasicResponse>('/api/tenant/DeleteUser', request);
  }
  
  sendInvitation(request: InvitationRequest) {
    return this.http.post('/api/tenant/SendInvitation', request);
  }

  resendInvitation(request: InvitationRequest) {
    return this.http.post('/api/tenant/ResendInvitation', request);
  }

  thereIsAnotherAdmin(userList: UserVm[], userId: string, tenantId: string): any {
    let adminRoles = this.appConfig.getAdminRoles();
    let filteredList = userList.filter(user => user.id !== userId);
    // Does any user (who has the current tenant) have roles AND there is an "administrator" one?
    let thereIsAnotherAdmin = filteredList.some(user => user.tenants?.find(tenant => tenant.id === tenantId)?.roles?.some(role => adminRoles.includes(role.id)));
    // let thereIsAnotherAdmin = filteredList.some(user => user.tenants?.find(tenant => tenant.id === tenantId)?.roles?.some(role => role.name === "administrator"));
    return thereIsAnotherAdmin
  }
}
