import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SHARED_COMPONENTS } from './components';
import { HttpClient } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { DisplayValuePipe } from './pipes/display-value.pipe';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MaterialModule, PlomoMaterialModule } from '@baks/plomo-material';
import { BottomSheetComponent } from './components/bottom-sheet/bottom-sheet.component';

const COMMON_MODULES = [CommonModule, FlexLayoutModule, RouterModule, MaterialModule,
  PlomoMaterialModule, ReactiveFormsModule, FormsModule, MatDialogModule, MatBottomSheetModule,
  MatDividerModule];

@NgModule({
  declarations: [DisplayValuePipe, SHARED_COMPONENTS, BottomSheetComponent],
  imports: [
    ...COMMON_MODULES,
    TranslateModule.forChild(),
  ],
  exports: [...COMMON_MODULES, TranslateModule, DisplayValuePipe]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      // providers
    };
  }
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  try {
    return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
  } catch (error) {
    throw error;
  }
}
