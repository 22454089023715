import { Injectable } from '@angular/core';
import { CookieType } from '../types';

/**
 * This services provides an API to manage the CRUD events of all the cookies, local & session Storage.
 */
@Injectable({
  providedIn: 'root',
})
export class BrowserStorageService {
  private readonly ONE_SECOND = 1;
  private readonly ONE_HOUR = 3600;
  private readonly ONE_MONTH = 3600 * 24 * 30;
  private readonly EXPIRED_COOKIE_DATE = 'Thu, 01 Jan 1970 00:00:00 UTC';
  private readonly APP_DOMAIN = 'azurewebsites.net';

  constructor() {}

  /* https://developer.mozilla.org/en-US/docs/Web/API/document/cookie
  ;domain=domain -  If not specified equals window.localtion.hostname
                    the first '.' its ignored, but the browsers cant prevent the cookie creation that contains that '.'
                    If it's specified the subdomains are always included
  ;path=path - If not specified the path if the current document.
  ;secure - Only HTTPS/SSL
  ;samesite=(lax or strict) - Prevent the browser to sent this cookie through cross-site requests.
  If max-age or expires isnt specified the cookie exists only while the browser still opened.
  ;max-age=max-duration in seconds
  ;expires=date in .toUTCString() format.

  Example: www.mydomain.org
  SLD (second level domain) => mydomain
  TLD (top level domain) => org
  */
  createCookie(cookieName: CookieType, value: string, numberOfDays?: number): void {
    // this doesnt set the cookie in localhost, only in APP_DOMAIN
    // const locationHostname = window.location.hostname;
    // const SLDandTLD = locationHostname.split('.').slice(-2).join('.');

    const locationHostname = window.location.hostname;

    if (locationHostname !== 'localhost') {
      // const SLDandTLD = locationHostname.split('.').slice(-2).join('.');

      // let cookieDomain = `domain=.${SLDandTLD}`;

      // if (cookieDomain === this.WDCMI_DOMAIN) {
      //   cookieDomain = `domain=.${window.location.hostname}`;
      // }

      // default one month of expiration
      let expiration = `max-age=${this.ONE_MONTH};`;

      if (numberOfDays) {
        expiration = `max-age=${3600 * 24 * numberOfDays};`;
      }
      document.cookie = `${cookieName}=${value}; secure; samesite=none; ${expiration}`;
    } else {
      // If we're running in localhost we just ignore the domain parameter
      const expiration = `max-age=${this.ONE_HOUR};`;
      document.cookie = `${cookieName}=${value}; ${expiration}`;
    }
  }

  getCookie(name: CookieType): string {
    const cookie: string = name + '=';
    let cookieValue = '';

    const allCookiesArray: string[] = this.getAllCookies();

    allCookiesArray.forEach((cookieItem) => {
      const trimmedCookie = cookieItem.trim();

      const cookieIndex = trimmedCookie.indexOf(cookie);

      if (cookieIndex !== -1) {
        cookieValue = trimmedCookie.substring(cookie.length, trimmedCookie.length);
      }
    });

    return cookieValue;
  }

  deleteCookie(name: string): void {
    const cookie: string = name + '=';
    document.cookie = `${cookie};domain=${this.APP_DOMAIN};max-age=${this.ONE_SECOND}`;
  }

  deleteAllCookies(): void {
    const allCookiesArray: string[] = this.getAllCookies();

    allCookiesArray.forEach((cookie: string) => {
      let cookieName: string = cookie.split('=')[0];
      cookieName = cookieName.trim();
      this.deleteCookie(cookieName);
    });
  }

  setItemToSessionStorage(key: string, item: string): void {
    sessionStorage.setItem(key, item);
  }

  setItemToLocalStorage(key: string, item: string): void {
    localStorage.setItem(key, item);
  }

  getItemFromSessionStorage(key: string): string {
    return sessionStorage.getItem(key);
  }

  getItemFromLocalStorage(key: string): string {
    return localStorage.getItem(key);
  }

  removeItemFromLocalStorage(key: string): void {
    localStorage.removeItem(key);
  }

  removeItemFromSessionStorage(key: string): void {
    sessionStorage.removeItem(key);
  }

  clearLocalStorage(): void {
    localStorage.clear();
  }

  clearSessionStorage(): void {
    sessionStorage.clear();
  }

  clearAllStorage(): void {
    this.clearSessionStorage();
    this.clearLocalStorage();
  }

  private getAllCookies(): string[] {
    return document.cookie.split(';');
  }
}
