import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private _isLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);

  isLoading$ = this._isLoading.asObservable();

  constructor() {}

  setLoading(value): void {
    this._isLoading.next(value);
  }

  isLoading(): boolean {
    return this._isLoading.value;
  }
}
