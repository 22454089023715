import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { BrowserStorageService, LoadingService } from '../services';
import { finalize } from 'rxjs/operators';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(private store: BrowserStorageService, private loadingService: LoadingService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loadingService.setLoading(true);
    let authReq: HttpRequest<any> = req;

    let accessToken: string;
    let containerToken: string;
    containerToken = this.store.getCookie('container_token');
    accessToken = this.store.getCookie('access_token');

    if (accessToken) {
      authReq = req.clone({
        headers: new HttpHeaders({
          'Asgard-Token': accessToken,
          'Content-Type': 'application/json',
          'Container-Token': containerToken
        }),
      });
    } else {
      authReq = req.clone({
        headers: req.headers.set('Content-Type', 'application/json'),
      });
    }

    return next.handle(authReq).pipe(
      finalize(() => {
        this.loadingService.setLoading(false);
      }),
    );
  }
}
