import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { CanDeactivate } from "@angular/router";

export interface CanComponentDeactivate {
    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
    providedIn: 'root'
})
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
    constructor( ) { }

    canDeactivate(component: CanComponentDeactivate){
        return component.canDeactivate();
    }

}