import { NgModule } from '@angular/core';
import { AuthGuard } from './core/guards/auth.guard';
import { Routes, RouterModule } from '@angular/router';
import { CanDeactivateGuard } from './core/guards/can-deactivate.guard';
import { ShellComponent, NotFoundComponent, LoginComponent } from './core/components';

const routes: Routes = [
  {
    path: '',
    component: ShellComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'link',
        loadChildren: () => import('./link/link.module').then((m) => m.LinkModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'tenant',
        loadChildren: () => import('./tenant/tenant.module').then((m) => m.TenantModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'profile',
        loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'profile/:tenantId',
        loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'backoffice',
        loadChildren: () => import('./backoffice/backoffice.module').then((m) => m.BackofficeModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'backoffice/:token',
        loadChildren: () => import('./backoffice/backoffice.module').then((m) => m.BackofficeModule),
        canActivate: [AuthGuard],
      },
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [CanDeactivateGuard]
})

export class AppRoutingModule {}
