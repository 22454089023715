import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TenantService } from './tenant/services/tenant.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpLoaderFactory, SharedModule } from './shared/shared.module';
import { RequestInterceptor } from './core/interceptor/request-interceptor';
import { NotificationIconPipe } from './core/pipes/notification-icon.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CustomMatPaginatorIntl } from './shared/components/custom-paginator-labels';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { AppConfigurationService, BrowserStorageService, NotificationService } from './core/services';
import { FooterComponent, LoadingSpinnerComponent, LoginComponent, NavBarComponent, NotFoundComponent,
  NotificationComponent, ShellComponent, SidebarMenuComponent } from './core/components';

const STATIC_IMPORTS = [
  BrowserAnimationsModule,
  BrowserModule,
  FormsModule,
  ReactiveFormsModule,
  HttpClientModule,
  SharedModule.forRoot(),
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient],
    },
    isolate: false,
  }),
];

const ROUTES_IMPORTS = [AppRoutingModule];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    FooterComponent,
    NavBarComponent,
    NotFoundComponent,
    ShellComponent,
    SidebarMenuComponent,
    NotificationComponent,
    LoadingSpinnerComponent,
    NotificationIconPipe
  ],
  imports: [...STATIC_IMPORTS, ...ROUTES_IMPORTS],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (appConfig: AppConfigurationService, notifService: NotificationService) => () => {
        return appConfig.loadConfigOnline().then(() => {
          notifService.connect();
        });
      },
      deps: [AppConfigurationService, NotificationService, TranslateService, BrowserStorageService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    TenantService,
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl
    }
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}
