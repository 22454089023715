export interface SidebarModel {
  content: string;
  url: string;
  disabled: boolean;
  icon?: string;
}

export const createSideBar = (sidebar: SidebarModel) => ({
  ...sidebar,
});
