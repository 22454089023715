import { Injectable } from '@angular/core';
import { AsgardResponseModel } from '@baks/types';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BrowserStorageService } from './browser-storage.service';
import { Observable, BehaviorSubject, of, throwError } from 'rxjs';
import { UserVm, createDefaultUserVm, UserResponse, ChangePasswordRequest, Tenant } from '../models';



@Injectable({
  providedIn: 'root',
})
export class LoginService {

  private _currentUser: UserVm;
  private appMode: BehaviorSubject<string> = new BehaviorSubject('');
  private tenants: BehaviorSubject<Tenant[]> = new BehaviorSubject([]);
  private _isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private currentUser: BehaviorSubject<UserVm> = new BehaviorSubject(createDefaultUserVm());

  constructor(private http: HttpClient, private storage: BrowserStorageService) {}

  doLogout() {
    this.storage.clearAllStorage();
    this.storage.deleteAllCookies();
    this._isLoggedIn.next(false);
    this.currentUser.next(undefined);
  }

  tenants$(): Observable<Tenant[]> {
    return this.tenants.asObservable();
  }

  appMode$(): Observable<string> {
    return this.appMode.asObservable();
  }

  isLoggedIn$(): Observable<boolean> {
    return this._isLoggedIn.asObservable();
  }

  currentUser$(): Observable<UserVm> {
    return this.currentUser.asObservable();
  }

  getCurrentUser(): UserVm {
    return this._currentUser;
  }

  isLoggedIn(): boolean {
    return this._isLoggedIn.getValue();
  }

  singleSignOn$(): Observable<UserResponse> {
    let getUser$: Observable<UserResponse>;
    let accessToken = this.storage.getCookie('access_token');

    if (accessToken) {
      getUser$ = this.http.get<UserResponse>('/api/login/GetUser');
    } else {
      getUser$ = throwError({ message: 'Unable to login through single sign on', statusCode: 400 });
    }

    return getUser$;
  }

  changePassword(request: ChangePasswordRequest) {
    return this.http.post('/api/login/ChangePassword', request);
  }

  deleteUser(userId: string) {
    const params = new HttpParams().append('userId', userId);
    return this.http.get<UserResponse>('/api/login/DeleteUser', {params});
  }

  getUserByEmail(email: string) {
    const params = new HttpParams().append('email', encodeURIComponent(email));
    return this.http.get<UserResponse>('/api/login/GetUserByEmail', {params});
  }

  public processAsgardResponse(asgardResponse: AsgardResponseModel) {
    const { accessToken, containerToken } = asgardResponse;

    if (accessToken == null || accessToken === '') {
      return throwError({ message: 'Received accessToken was empty', statusCode: 500 });
    }

    this.storage.createCookie('access_token', accessToken);
    this.storage.createCookie('container_token', containerToken);

    return of(asgardResponse);
  }

  setCurrentUser(user: UserVm) {
    this._currentUser = {...user};
    this.currentUser.next(user);
  }

  setIsLoggedIn(logged: boolean) {
    this._isLoggedIn.next(logged);
  }

  setMode(mode: string) {
    this.appMode.next(mode);
  }

  setTenants(tenants: Tenant[]) {
    this.tenants.next(tenants);
  }
}
