import { Observable, Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { LoginService, SidebarService, ThemeService } from '../../services';

@Component({
  selector: 'app-shell-component',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnInit {
  private subs: Subscription[] = [];
  isOpen$: Observable<boolean>;
  isLoggedIn$: Observable<boolean>;
  mode$: Observable<string>;
  isFramelessMode: boolean = false;

  constructor(
    private loginService: LoginService,
    private themeService: ThemeService,
    private sidebarService: SidebarService
  ) {}

  ngOnInit(): void {
    this.isOpen$ = this.sidebarService.isOpen$;
    this.mode$ = this.sidebarService.mode$;
    this.isLoggedIn$ = this.loginService.isLoggedIn$();
    this.subs.push(
      this.themeService.isFramelessMode$.subscribe(isFrameless => this.isFramelessMode = isFrameless)
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
